    .sms-chat {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }

    .sms-messages {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }

    .sms-message {
      font-size: 1.5rem;
      border-radius: 20px;
      padding: 8px 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      display: inline-block;
    }

    .sms-yours {
      align-items: flex-start;
    }

    .sms-yours .sms-message {
      margin-right: 25%;
      background-color: #eee;
      position: relative;
    }

    .sms-yours .sms-message.last:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #eee;
      border-bottom-right-radius: 15px;
    }

    .sms-yours .sms-message.last:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-right-radius: 10px;
    }

    .sms-mine {
      align-items: flex-end;
    }

    .sms-mine .sms-message {
      color: white;
      margin-left: 25%;
      background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
      background-attachment: fixed;
      position: relative;
    }

    .sms-mine .sms-message.last:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: -8px;
      height: 20px;
      width: 20px;
      background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
      background-attachment: fixed;
      border-bottom-left-radius: 15px;
    }

    .sms-mine .sms-message.last:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-left-radius: 10px;
    }